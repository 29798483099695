/* Intro */

.RS-intro {
  position: relative;
  z-index: 0;
  padding: 12vh var(--RS-space-24) 4vh;
  display: flex;
  align-items: flex-end;
  height: 90vh;

  @media (--h-mobile-viewport) {
    height: auto;
    min-height: 100vh;
  }

  @media (--lg-viewport) {
    height: 100vh;
    min-height: unset;
    padding: 14vh var(--RS-space-32) 8vh;
    margin: 0 0 var(--RS-space-32);
  }
}

.RS-intro-careers{
  height: auto;
  min-height: 90vh;

  --RS-orbit-xy: calc(var(--RS-space-256) + var(--RS-space-32));
  --RS-background-step: 10vh;

  @media (--sm-viewport) {
    --RS-orbit-xy: calc(var(--RS-space-256) + var(--RS-space-128) + var(--RS-space-32));
  }

  @media (--md-viewport) {
    --RS-orbit-xy: calc(100% + var(--RS-space-32)*2);
    --RS-background-step: 25vh;
  }

  @media (--lg-viewport) {
    height: 100vh;
    min-height: unset;
  }
}

.RS-intro-founder{
  padding: 8vh var(--RS-space-32) 8vh;

  @media (--md-viewport) {
    /* padding: 8vh var(--RS-space-32) 8vh; */
  }

  @media (--lg-viewport) {
    padding: 8vh var(--RS-space-32) 8vh;
  }
}

.RS-intro--thank-you{
  align-items: flex-start;
  height: auto;
  min-height: 75vh;
}

.RS-intro--connect{
  padding: 8vh var(--RS-space-32) 0vh;

  @media (--lg-viewport) {
    padding: 10vh var(--RS-space-32) 6vh;
  }
}

.RS-intro__container {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: var(--RS-col-8);
  min-height: 100%;
  margin: 0 auto;
  height: 100%;

  @media (--lg-viewport) {
    flex-direction: row-reverse;
  }

}

.RS-intro__container--founder{
  align-items: center;
}

.RS-intro__container--connect{
  max-width: 100%;
  flex-direction: column;

  background-color: var(--RS-space-grey-color);
  border-radius: var(--RS-space-16);
  clip-path: polygon(0 0, 0% calc(50% - var(--RS-space-12)), calc(0% + var(--RS-space-12)) 50%, 0% calc(50% + var(--RS-space-12)), 0 100%, 100% 100%, 100% calc(50% + var(--RS-space-12)), calc(100% - var(--RS-space-12)) 50%, 100% calc(50% - var(--RS-space-12)), 100% 0);
  position: relative;
  overflow: hidden;


  @media (--lg-viewport) {
    flex-direction: row;
    border-radius: var(--RS-space-32);
    /* clip-path: polygon(calc(50% - var(--RS-space-16) - var(--RS-space-2)) 0%, 0 0, 0 100%, calc(50% - var(--RS-space-16) - var(--RS-space-2)) 100%, 50% calc(100% - var(--RS-space-16)), calc(50% + var(--RS-space-16) + var(--RS-space-2)) 100%, 100% 100%, 100% 0, calc(50% + var(--RS-space-16) + var(--RS-space-2)) 0%, 50% calc(0% + var(--RS-space-16))); */
    clip-path: polygon(calc(50% - var(--RS-space-16)) 0%, 0 0, 0 100%, calc(50% - var(--RS-space-16)) 100%, 50% calc(100% - var(--RS-space-16)), calc(50% + var(--RS-space-16)) 100%, 100% 100%, 100% 0, calc(50% + var(--RS-space-16)) 0%, 50% calc(0% + var(--RS-space-16)));
  }
}

.RS-intro__container--connect:before,
.RS-intro__container--connect:after{
  content: '';
  position: absolute;
  left: calc(var(--RS-space-24) + var(--RS-space-2));
  top: calc(50% - var(--RS-space-2));
  width: calc(100% - (var(--RS-space-24) + var(--RS-space-2))*2);
  height: var(--RS-space-4);
  background-color: var(--RS-space-black-color);
  z-index: 2;
  border-radius: var(--RS-space-4);

  @media (--lg-viewport) {
    top: calc(var(--RS-space-32) + var(--RS-space-2));
    left: calc(50% - var(--RS-space-2));
    width: var(--RS-space-4);
    height: calc(100% - (var(--RS-space-32) + var(--RS-space-2))*2);
  }
}

/* .RS-intro__container--connect:after{
  width: 100%;
  height: var(--RS-space-2);
  background-color: var(--RS-the-red-color);
  transform-origin: center center;
  animation: animTopDown 10s linear infinite;
  animation-delay: 2s;
  transform: scale(0,0);
  opacity: 0;

  @media (--lg-viewport) {
    height: calc(100% - var(--RS-space-32)*2);
    top: var(--RS-space-32);
    width: var(--RS-space-2);
    mask-image: linear-gradient(180deg, transparent 0%, rgba(0, 0, 0, 1) 20% 80%, transparent 100%);
  }
}

@keyframes animTopDown {
  0% {
    transform: scale(0,0);
    opacity: 0;
  }
  15%{
    opacity: 0;
    transform: scale(0,0);
  }
  50%{
    transform: scale(1,1);
    opacity: 1;
  }
  85%{
    opacity: 0;
    transform: scale(1,1);
  }

  100% {
    transform: scale(0,0);
    opacity: 0;
  }
} */


.RS-intro__content {
  margin-right: auto;
  width: 100%;

  @media (--lg-viewport) {
    max-width: var(--RS-col-4);
    max-width: 50%;
    margin-right: unset;
  }
}

.RS-intro__container--founder .RS-intro__content{
  display: flex;
  flex-direction: column;
  @media (--lg-viewport) {
    max-width: 100%;
  }
}

.RS-intro__container--thank-you .RS-intro__content{
  max-width: 100%;
}


.RS-intro__container--connect .RS-intro__content{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  /* background: var(--RS-space-grey-color); */
  gap: var(--RS-space-32);
  max-width: calc(100% - var(--RS-space-32)*2);
  margin: 0 auto;

  
  @media (--lg-viewport) {
    max-width: 100%;
  } 
}

.RS-intro__container--connect .RS-intro__content:first-of-type{
  /* border-radius: var(--RS-space-24) var(--RS-space-24) 0 0;
  clip-path: polygon(100% 0, 100% calc(100% - var(--RS-space-16)), calc(100% - var(--RS-space-16)) 100%, var(--RS-space-16) 100%, 0% calc(100% - var(--RS-space-16)), 0 0);

  margin-bottom: var(--RS-space-1);

  @media (--lg-viewport) {
    border-radius: var(--RS-space-32) 0 0 var(--RS-space-32);
    clip-path: polygon(0 0, calc(100% - var(--RS-space-16)) 0%, 100% var(--RS-space-16), 100% calc(100% - var(--RS-space-16)), calc(100% - var(--RS-space-16)) 100%, 0 100%);
    margin-right: var(--RS-space-1);
    margin-bottom: 0;
  } */
}

.RS-intro__container--connect .RS-intro__content:last-of-type{
  /* border-radius: 0 0 var(--RS-space-24) var(--RS-space-24);
  clip-path: polygon(var(--RS-space-16) 0%, calc(100% - var(--RS-space-16)) 0%, 100% var(--RS-space-16), 100% 100%, 0 100%, 0% var(--RS-space-16));
  margin-top: var(--RS-space-1);

  @media (--lg-viewport) {
    border-radius: 0 var(--RS-space-32) var(--RS-space-32) 0;
    clip-path: polygon(var(--RS-space-16) 0%, 100% 0, 100% 100%, var(--RS-space-16) 100%, 0% calc(100% - var(--RS-space-16)), 0% var(--RS-space-16));
    margin-left: var(--RS-space-1);
    margin-top: 0;
  } */
}

.RS-intro__artwork {
  width: 100%;
  z-index: -1;
  transform: translateY(0);
  position: relative;
  height: 100%;
  pointer-events: none;

  @media (--sm-viewport) {
    width: 520px;
  }

  @media (--md-viewport) {
    width: 564px;
    max-width: 80%;
    margin-left: auto;
  }

  @media (--lg-viewport) {
    width: 508px;
    margin: 0;
  }

  @media (--xlg-viewport) {
    width: 642px;
  }

  @media (--2xlg-viewport) {
    transform: translateY(5vh);
  }

  @media (--h-mobile-viewport) {
    width: 46%;
  }
}


.RS-intro__container--founder .RS-intro__artwork{
  width: 100%;
  max-width: 100%;
  transform: unset;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
}

.RS-intro__artwork--industry{
  transform: none;
}

.RS-intro__headline {
  margin: 0 0 var(--RS-space-6) 0;
  font-size: var(--RS-font-size-h3);
  line-height: var(--RS-line-height-xlg);
  letter-spacing: .075em;

  @media (--md-viewport) {
    margin: 0 0 var(--RS-space-16) 0;
  }
}

.RS-intro__title {
  margin: 3vh 0;
  font-size: var(--RS-font-size-h1-intro);
  line-height: var(--RS-line-height-md);
  transition-duration: 1s;

  @media (--md-viewport) {
    margin: 6vh 0;
  }

  @media (--lg-viewport) {
    font-size: var(--RS-font-size-h1-intro-desktop);
  }
}

.RS-intro__container--founder .RS-intro__title{
  text-align: center;
  margin: 2vh 0;

  @media (--md-viewport) {
    margin: 4vh 0;
  }
}

.RS-intro__container--connect .RS-intro__title{
  font-size: var(--RS-font-size-h1);
  /* font-weight: var(--RS-font-weight-variable-bold); */
  text-align: center;
  margin: 0;

  @media (--lg-viewport) {
    font-size: var(--RS-font-size-h1-desktop);
    margin: 0;
  }
}

.RS-intro__job-promo,
.RS-intro__artwork > svg {
  transition-duration: 1.5s;
  width: auto;
}

.RS-intro__container--founder .RS-intro__job-promo{
  margin: 0 auto;
}

.RS-intro-artwork__bicycle .RS-animation__artwork-shadow {
  transform: translate(-8px, 21px);
  animation-duration: 8s;
}

.RS-intro-artwork__bicycle .RS-animation__artwork-content {
  animation-duration: 8s;
}

.RS-intro__artwork-inner{
  position: absolute;
  width: 100%;
  height: inherit;
}

.RS-intro__artwork > .RS-animation{
  height: inherit;
}

.RS-intro__description{
  font-size: var(--RS-font-size-h1);
  line-height: var(--RS-line-height-lg);
  margin: 0 0 6vh;
  @media (--xlg-viewport) {
    font-size: var(--RS-font-size-h1-desktop);
    line-height: var(--RS-line-height-md);
  }
}

.RS-intro-careers__container{
  position: initial;
  justify-content: flex-end;
  /* flex-direction: column-reverse; */
  flex-direction: column-reverse;

  @media (--lg-viewport) {
    flex-direction: row-reverse;
  }

}

.RS-intro__photos{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  

  padding-top: var(--RS-space-32);

  position: relative;
  width: 100%;

  @media (--sm-viewport) {
    gap: var(--RS-space-32);
  }

  @media (--lg-viewport) {
    position: absolute;
    height: 100%;
    top: 0;
    width: calc(50vw + var(--RS-space-16));
    right: var(--RS-space-24-n);
  }
}

.RS-intro__photos-col{
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: var(--RS-space-32);
  position: relative;
  
}

.RS-intro__photos img{
  margin: 0 auto;
  border-radius: var(--RS-space-12);
}

.RS-intro__photo > picture{
  /* overflow: hidden; */
}


.RS-intro__photo-vertical{
  display: none;
  transform: translate3d(0px,0px,0px) rotate(var(--RS-rotate-firefox-fix));

  @media (--sm-viewport) {
    display: initial;
    animation: animImageVertical 5.5s var(--RS-default-transition) infinite;
  }
}

@keyframes animImageVertical {
	0%,100%{
    transform: translate3d(0px,0px,0px) rotate(var(--RS-rotate-firefox-fix));
  }
  50%{
    transform: translate3d(-3px, 0px,0px) rotate(var(--RS-rotate-firefox-fix));
  }
}
.RS-intro__photo-horizontal{
  transform-origin: bottom right;
  transform: translate3d(0px,0px,0px) rotate(var(--RS-rotate-firefox-fix));
  animation-delay: calc(var(--RS-anim-delay) * 2);
  outline: 1px solid transparent;


  @media (--sm-viewport) {
    display: initial;
    animation: animImageHorizontal 5.5s var(--RS-default-transition) infinite;
  }
}

@keyframes animImageHorizontal {
	0%,100%{
    transform: translate3d(0px,0px,0px) rotate(var(--RS-rotate-firefox-fix));
  }
  50%{
    transform: translate3d(-3px,-3px,0px) rotate(var(--RS-rotate-firefox-fix));
  }
}


.RS-intro__photo-square{
  max-width: 50%;
  display: none;

  transform-origin: top right;
  transform: translate3d(0px,0px,0px) rotate(var(--RS-rotate-firefox-fix));
  animation-delay: calc(var(--RS-anim-delay) * 4);

  @media (--sm-viewport) {
    display: initial;
    animation: animImageSquare 5.5s var(--RS-default-transition) infinite;
  }
}



@keyframes animImageSquare {
	0%,100%{
    transform: translate3d(0px,0px,0px) rotate(var(--RS-rotate-firefox-fix));
  }
  50%{
    transform: translate3d(-3px,3px,0px) rotate(var(--RS-rotate-firefox-fix));
  }
}

.RS-intro__photo-vertical.RS-looped-animation--running,
.RS-intro__photo-square.RS-looped-animation--running,
.RS-intro__photo-vertical .RS-image-glitch-overlay.RS-looped-animation--running,
.RS-intro__photo-square .RS-image-glitch-overlay.RS-looped-animation--running{
  animation-play-state: paused!important;

  @media (--sm-viewport) {
    animation-play-state: inherit!important;
  }
}

.RS-intro__slider-picture{
  display: none;
  /* opacity: 0; */
  /* transform: scale(.98,.98); */
  /* transition: transform var(--RS-anim-duration-md) var(--RS-default-transition), opacity var(--RS-anim-duration-lg) var(--RS-default-transition); */
}

.RS-intro__slider-picture--displayed{
  display: block;
}

.RS-intro__slider-picture--visible{
  /* opacity: 1; */
  /* transform: scale(1,1); */
}

.RS-intro__photo-connect{
  position: absolute;
  height: 100%;
  width: 100%;
}


.RS-intro__photo-square img{
  aspect-ratio: 1/1;
}

.RS-intro__photo-vertical img{
  aspect-ratio: 0.665/1;
}

.RS-intro__photo-horizontal img{
  aspect-ratio: 1.5/1;
}

.RS-intro__photo-connect img{
  object-fit: cover;
  object-position: 50% 0%;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0%;
  left: 0%;
}


/* .RS-intro__photo-connect img:last-of-type{
  mix-blend-mode: soft-light;
  position: absolute;
  left: 0;
  top: 0;
  opacity: .5;
  mask-image: radial-gradient(33% 33%, #0000 67%, #000 100%);
} */


.RS-intro__photos-background{
 position: absolute;
 width: calc(200% + var(--RS-space-64));
 right: 0;
 height: calc(100% + var(--RS-space-32)*2);
 top: var(--RS-space-32-n);
 background: var(--RS-space-grey-color);
 z-index: -1;
 border-radius: var(--RS-space-24);

 display: none;

 @media (--sm-viewport) {
   display: initial;
 }
}

.RS-image-glitch-overlay{
  position: absolute;
  top: 0;
}

.RS-image-glitch-overlay--first{
  transform: translate3d(0px,0px,0px) scale(.96);
  transform-origin: bottom left;
  animation: animImageGlitchOverlayFirst 8s linear infinite;
  mix-blend-mode: lighten;
  animation-direction: alternate;
  opacity: 0;
}

@keyframes animImageGlitchOverlayFirst {
  0%,34%,36%,66.5%,68.5%,100%{
    opacity: 0;
    transform: translate3d(0px,0px,0px) scale(.8);
  }
  35%,67.5%{
    opacity: 1;
    transform: translate3d(-5px,4px,0px) scale(.8);
  }
}

.RS-image-glitch-overlay--second{
  transform: translate3d(0px,0px,0px) scale(.96);
  transform-origin: top right;
  animation: animImageGlitchOverlaySecond 8s linear infinite;
  mix-blend-mode: darken;
  animation-direction: alternate;
  opacity: 0;
}

@keyframes animImageGlitchOverlaySecond {
  0%,34%,36%,66.5%,68.5%,100%{
    opacity: 0;
    transform: translate3d(0px,0px,0px) scale(.8);
  }
  35%,67.5%{
    opacity: 1;
    transform: translate3d(5px,-4px,0px) scale(.8);
  }
}

.RS-image-glitch-overlay--vertical-top{
  clip-path: polygon(0 0, 0% 100%, 100% 100%);
  animation-delay: calc(var(--RS-anim-delay) * 0);
}

.RS-image-glitch-overlay--vertical-bottom{
  clip-path: polygon(100% 0, 0 0, 100% 100%);
  animation-delay: calc(var(--RS-anim-delay) * 2);
}

.RS-image-glitch-overlay--horizontal-top{
  clip-path: polygon(0 0, 100% 0, 100% 33%, 0 33%);
  animation-delay: calc(var(--RS-anim-delay) * 4);
}

.RS-image-glitch-overlay--horizontal-bottom{
  clip-path: polygon(0 67%, 100% 67%, 100% 100%, 0 100%);
  animation-delay: calc(var(--RS-anim-delay) * 6);
}


.RS-image-glitch-overlay--square-left{
  clip-path: polygon(0 0, 33% 0, 33% 100%, 0 100%);
  animation-delay: calc(var(--RS-anim-delay) * 8);
}

.RS-image-glitch-overlay--square-right{
  clip-path: polygon(67% 0, 100% 0, 100% 100%, 67% 100%);
  animation-delay: calc(var(--RS-anim-delay) * 10);
}

.RS-image-glitch-overlay--background-top{
  clip-path: polygon(0 0, 100% 0, 100% 10%, 0 10%);
  animation-delay: calc(var(--RS-anim-delay) * 4);
}

.RS-image-glitch-overlay--background-bottom{
  clip-path: polygon(0 90%, 100% 90%, 100% 100%, 0 100%);
  animation-delay: calc(var(--RS-anim-delay) * 6);
}


.RS-intro-careers .RS-background__logo{
  left: calc(0% - var(--RS-orbit-xy) / 2);
  top: calc(50% - var(--RS-orbit-xy) / 4);
  mix-blend-mode: soft-light;
  display: none;

  @media (--sm-viewport) {
    display: initial;
  }
}

.RS-intro-careers .RS-inner-background-logo{
  opacity: 1;
  animation: animImageLogo 27.5s linear infinite;
}

@keyframes animImageLogo{
  0%{
    transform: rotateZ(0deg);
  }
  100%{
    transform: rotateZ(-360deg);
  }
}

.RS-intro__text-glitch{
  /* animation: animTextGlitch 1s linear infinite; */
  transform: translate3d(0px,0px,0px) skew(0deg);
  position: relative;
  display: inline-block;
}

@keyframes animTextGlitch{
  2%,64%{
    transform: translate3d(2px,0px,0px) skew(0deg);
  }
  4%,60%{
    transform: translate3d(-2px,0px,0px) skew(0deg);
  }
  62%{
    transform: translate3d(0px,0px,0px) skew(5deg); 
  }
}

.RS-intro__text-glitch:before,
.RS-intro__text-glitch:after{
  content: attr(title);
  position: absolute;
  left: 0;
  transform: translate3d(0px,0px,0px) skew(0deg);
}

.RS-intro__text-glitch:before{
  animation: animTextGlitchTop 1s linear infinite;
  clip-path: polygon(0 0, 100% 0, 100% 33%, 0 33%);
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 33%, 0 33%);
}

@keyframes animTextGlitchTop{
  60%,64%{
    transform: translate3d(0px, 0px,0px);
  }
  62%{
    transform: translate3d(13px,-1px,0px) skew(-13deg); 
  }
}

.RS-intro__text-glitch:after{
  animation: animTextGlitchBottom 1.5s linear infinite;
  clip-path: polygon(0 67%, 100% 67%, 100% 100%, 0 100%);
  -webkit-clip-path: polygon(0 67%, 100% 67%, 100% 100%, 0 100%);
}

@keyframes animTextGlitchBottom{
  60%,64%{
    transform: translate3d(0px,0px,0px);
  }
  62%{
    transform: translate3d(-22px,5px,0px) skew(21deg);
  }
}


.RS-intro-founder-background{
  position: absolute;
  width: inherit;
  height: inherit;
  top: unset;
  left: unset;
  z-index: -1;

  --RS-orbit-xy: calc(var(--RS-space-256) + var(--RS-space-128));
  --RS-background-step: 20vh;

  @media (--sm-viewport) {
    --RS-orbit-xy: calc(var(--RS-space-256) + var(--RS-space-256));
    --RS-background-step: 25vh;
  }


  @media (--md-viewport) {
    --RS-orbit-xy: calc(var(--RS-space-256) + var(--RS-space-256) + var(--RS-space-64));
    --RS-background-step: 30vh;
  }

  
}
.RS-intro-founder-background-inner{
  width: inherit;
  height: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
}

.RS-intro-founder-background-logo{
  backface-visibility: hidden;
  position: absolute;
  width: calc(var(--RS-orbit-xy) * 0.25);
  height: calc(var(--RS-orbit-xy) * 0.25);
  z-index: 2;

  @media (--md-viewport) {
    width: calc(var(--RS-orbit-xy) * 0.225);
    height: calc(var(--RS-orbit-xy) * 0.225);
  }
}

.RS-intro-founder-background-logo:nth-of-type(1){
  top: 4%;
  left: 4%;
}

.RS-intro-founder-background-logo:nth-of-type(2){
  bottom: 4%;
  right: 4%;

  @media (--md-viewport) {
  }
}

.RS-intro-founder-background-icon{
  backface-visibility: hidden;
  position: absolute;
  width: calc(var(--RS-orbit-xy) * 0.05);
  height: calc(var(--RS-orbit-xy) * 0.05);
  z-index: 2;

  @media (--md-viewport) {
    width: calc(var(--RS-orbit-xy) * 0.05);
    height: calc(var(--RS-orbit-xy) * 0.05);
  }
}

.RS-intro-founder-background-icon:nth-of-type(1){
  top: 12.75%;
  left: 12.75%;
}

.RS-intro-founder-background-orbit:nth-of-type(3) > .RS-intro-founder-background-icon:nth-of-type(1){
  top: 50%;
  left: -1.5%;
}

.RS-intro-founder-background-icon:nth-of-type(2){
  bottom: 12.75%;
  right: 12.75%;
}

.RS-intro-founder-background-orbit:nth-of-type(3) > .RS-intro-founder-background-icon:nth-of-type(2){
  top: 50%;
  right: -1.5%;
}

.RS-intro-founder-background-icon:nth-of-type(3){
  bottom: 12.75%;
  left: 12.75%;
}

.RS-intro-founder-background-icon:nth-of-type(4){
  top: 12.75%;
  right: 12.75%;
}



.RS-intro-founder-background-logo:before,
.RS-intro-founder-background-icon:before{
  content: '';
  position: absolute;
  left: -12.5%;
  top: -12.5%;
  display: block;
  width: 125%;
  height: 125%;
  background: var(--RS-space-black-color);
  border-radius: 50%;
}

.RS-intro-founder-background-icon:before{
  left: -50%;
  top: -50%;
  width: 200%;
  height: 200%;
}

.RS-intro-founder-background-logo-inner,
.RS-intro-founder-background-icon-inner{
  opacity: .21;
  position: relative;
  overflow: visible;
  z-index: 1;
}


.RS-intro-founder-background-logo:nth-of-type(2) > .RS-intro-founder-background-logo-inner{
  transform: translate(5%,-5%);
  animation-delay: 5s;
}

.RS-intro-founder-background-icon:nth-of-type(2) > .RS-intro-founder-background-icon-inner{
  animation-delay: 1.25s;
}

.RS-intro-founder-background-icon:nth-of-type(3) > .RS-intro-founder-background-icon-inner{
  transform: translate(10%, 0%);
  animation-delay: 2.5s;
}

.RS-intro-founder-background-icon:nth-of-type(4) > .RS-intro-founder-background-icon-inner{
  transform: translate(-10%, -10%);
  animation-delay: 3.75s;
}

.RS-intro-founder-background-orbit{
  position: absolute;
  border: 2px solid rgba(255,255,255,.21);
  border-radius: 50%;
  width: var(--RS-orbit-xy);
  height: var(--RS-orbit-xy);
  opacity: 1;
  transition: transform var(--RS-anim-duration-lg) var(--RS-default-transition), opacity var(--RS-anim-duration-md) var(--RS-default-transition);
  backface-visibility: hidden;
  pointer-events: none;
  user-select: none;

  @media (--md-viewport) {
    opacity: 1;
  }
}



.RS-intro-founder-background-orbit:nth-of-type(2){
  width: calc(var(--RS-orbit-xy) + var(--RS-background-step)*1);
  height: calc(var(--RS-orbit-xy)+ var(--RS-background-step)*1);
  top: unset;
  left: unset;
}

.RS-intro-founder-background-orbit:nth-of-type(3){
  width: calc(var(--RS-orbit-xy) + var(--RS-background-step) * 1.66);
  height: calc(var(--RS-orbit-xy) + var(--RS-background-step) * 1.66);
  top: unset;
  left: unset;
  /* border: 2px solid rgba(255,255,255,0); */
  border: unset;
}


.RS-intro--newsletter{
  height: auto;
  min-height: 90vh;

  @media (--h-mobile-viewport) {
    height: auto;
    min-height: 100vh;
  }

  @media (--lg-viewport) {
    height: auto;
    min-height: unset;
    padding: 14vh var(--RS-space-32) 8vh;
    margin: 0 0 var(--RS-space-32);
  }
}

.RS-intro--newsletter__container{
  justify-content: space-between;
  /* flex-direction: column-reverse;
  
  @media (--lg-viewport) {
    flex-direction: row-reverse;
  } */
}

.RS-intro--newsletter__content{
  width: 100%;

  @media (--lg-viewport) {
    max-width: var(--RS-col-5);
  }
}
  

.RS-intro--newsletter__artwork {
  width: 280px;
  max-width: 60%;
  z-index: -1;
  transform: translateY(0);
  position: relative;
  height: 100%;
  pointer-events: none;

  @media (--md-viewport) {
    width: 340px;
  }

  @media (--lg-viewport) {
    width: 440px;
    margin: 0;
    margin-bottom: 3vh;
  }

  @media (--xlg-viewport) {
    width: 460px;
  }


  @media (--h-mobile-viewport) {
    width: 46%;
  }
}


.RS-intro--newsletter__artwork-inner{
  position: relative;
}




.RS-intro-founder-background--industry{
  --RS-orbit-xy: calc(var(--RS-space-256) + var(--RS-space-128));
  --RS-background-step: 12.5vh;

  @media (--sm-viewport) {
    --RS-orbit-xy: calc(var(--RS-space-256) + var(--RS-space-256));
    --RS-background-step: 12.5vh;
  }

  @media (--md-viewport) {
    --RS-orbit-xy: calc(var(--RS-space-256) + var(--RS-space-128) + var(--RS-space-64));
    --RS-background-step: 15vh;
  }
}


.RS-intro-founder-background-orbit--industry{
  z-index: 1;
}

.RS-intro-founder-background-orbit--industry:nth-of-type(2){
  border: unset;
}

.RS-intro-founder-background__industry{
  /* width: 75%; */
  position: relative;
  z-index: 0;
}


.RS-intro-founder-background__industry--climate-tech{
  width: 47.5%;

  @media (--md-viewport) {
    width: 42.5%;
  }
}

.RS-intro-founder-background__industry--healthcare{
  width: 45.5%;

  @media (--md-viewport) {
  width: 40.5%;
  }
}

.RS-intro-founder-background__industry--cybersecurity{
  width: 32.5%;

  @media (--md-viewport) {
    width: 27.5%;
  }
}


.RS-intro-founder-background__industry--ai-infrastructure{
  left: -2%;
  top: -2%;
  width: 48%;

  @media (--md-viewport) {
    width: 41%;
  }
}

.RS-intro-founder-background__industry--ai{
  left: 0%;
  top: -2%;
  width: 50.5%;

  @media (--md-viewport) {
    width: 43.5%;
  }
}

.RS-intro-founder-background__industry > span{

  display: block;
  animation: animFounderIndustryKv 4.5s linear infinite;
}

@keyframes animFounderIndustryKv{
  50%{
    transform: translate3d(0,5%,0);
  }
  /* 67%{
    transform: translate3d(0,-3.5%,0); 
  } */
}

.RS-intro-founder-background__industry svg{
  overflow: visible;
}

.RS-intro-founder-background-icon--industry:nth-of-type(1){
  backface-visibility: hidden;
  position: absolute;
  width: calc(var(--RS-orbit-xy) * 0.1);
  height: calc(var(--RS-orbit-xy) * 0.1);
  z-index: 2;
  top: 10%;
  left: 10%;

  @media (--md-viewport) {
    width: calc(var(--RS-orbit-xy) * 0.125);
    height: calc(var(--RS-orbit-xy) * 0.125);
    top: 8%;
    left: 8%;
  }
}

.RS-intro-founder-background-icon--industry:nth-of-type(1):before{
  width: 160%;
  height: 160%;
  top: -30%;
  left: -30%;
}

.RS-intro-founder-background-icon--industry:nth-of-type(1):after{
  content: '';
  position: absolute;
  left: -15%;
  top: -15%;
  display: block;
  width: 130%;
  height: 130%;
  border-radius: 50%;
  background: var(--RS-white-color);
  z-index: 0;
}

.RS-intro-founder-background-icon--industry:nth-of-type(1) .RS-intro-founder-background-logo-inner{
  width: 65%;
  height: 65%;
  top: 17.5%;
  left: 17.5%;
}

.RS-intro-founder-background-icon--industry:nth-of-type(1) > .RS-intro-founder-background-logo-inner--industry-cybersecurity{
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}

.RS-intro-founder-background-icon--industry:nth-of-type(1) > .RS-intro-founder-background-logo-inner--industry-ai-infrastructure{
  width: 70%;
  height: 70%;
  left: 15%;
  top: 15%;
}

.RS-intro-founder-background-icon--industry:nth-of-type(2) .RS-intro-founder-background-icon-inner path{
  /* fill: var(--RS-white-color); */
  /* fill: var(--RS-the-red-color); */
  /* stroke: unset; */
}

.RS-intro-founder-background-icon--industry:nth-of-type(2){
  bottom: 12%;
  right: 12%;
}

.RS-intro-founder-background-orbit--industry:nth-of-type(2) .RS-intro-founder-background-icon:nth-of-type(1){
  bottom: 12.75%;
  left: 12.75%;
  top: unset;
}

.RS-intro-founder-background-orbit--industry:nth-of-type(2) .RS-intro-founder-background-icon:nth-of-type(2){
  top: 12.75%;
  right: 12.75%;
  left: unset;
  bottom: unset;
}

/* .RS-intro--connect__detail{
  position: absolute;
  width: var(--RS-space-32);
  height: var(--RS-space-32);
  left: calc(50% - var(--RS-space-32)/2);
  top: calc(50% - var(--RS-space-32)/2);
  transform: rotateZ(45deg);
  background-color: var(--RS-space-black-color);
  border-radius: var(--RS-space-4);
} */